import { useEffect, useState } from 'react';
import StepperControl from './components/StepperControl';
import Card from 'components/card';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import TextField from 'components/fields/TextField';
import Checkbox from 'components/checkbox';
import Select from 'react-select';
import InputField from 'components/fields/InputField';

const industriesPricing: any = {
  Rénovation: {
    pricing: ['25-30', '30-35', '35-40'],
    fields: [
      'Propriétaire ou locataire',
      'Maison ou appartement',
      'Type de chauffage',
      "Montant facture d'électricité annuel",
      'Departement',
    ],
  },
  Formation: {
    pricing: ['15-20', '20-25', '25-30', '30+'],
    fields: [
      'Situation ( salarié, en recherche d’emploi, indépendant, a la retraite)',
      'Situation pro durant les deux dernières années',
    ],
  },
  Investissement: {
    pricing: ['40-50', '50-60', '60-70', '70+'],
    fields: [
      'Situation marital',
      'Situation Pro',
      'Propriétaire ou locataire',
      'Montant des impôts annuels',
    ],
  },
  Assurance: {
    pricing: ['25-30', '30-35', '35-40'],
    fields: [],
  },
  Autre: {
    pricing: [
      '15-20',
      '20-25',
      '25-30',
      '30-35',
      '35-40',
      '40-45',
      '45-50',
      '50-55',
      '55-60',
    ],
    fields: [],
  },
};

const allChannels = [
  'Google Ads',
  'Bing Ads',
  'Taboola',
  'Facebook',
  'Instagram',
  'Twitter',
  'LinkedIn',
  'Pinterest',
  'SEO',
  'Emailing',
  'SMS Marketing',
  'Newsletter',
  'Publicités display',
  'Tik Tok',
];

const industries: any = {
  Rénovation: [
    { name: 'Pompe à chaleur', sub: [] },
    { name: 'Panneaux solaire', sub: [] },
    { name: 'Rénovation global', sub: [] },
    { name: 'ITE', sub: [] },
    { name: 'Autre', sub: [] },
  ],
  Formation: [
    {
      name: 'Langues',
      sub: [
        'Anglais',
        'Espagnol',
        'Italien',
        'Chinois',
        'Japonais',
        'Russe',
        'Portugais',
        'Allemand',
      ],
    },
    {
      name: 'Généraliste',
      sub: [
        'Excel',
        'Photoshop',
        'llustrator',
        'WordPress',
        'sketchup',
        'création entreprise',
        'bilan de compétences',
      ],
    },
    {
      name: 'Niche',
      sub: [
        'Prothésiste ongulaire',
        'VTC',
        'CACES conducteur d’engins',
        'Maquillage professionnelle',
        'Patisserie',
        'Nutritionniste',
        'Electricien',
        'Plombier',
        'Reparation velo',
        'Reparation smartphone',
        'Coach sportif',
      ],
    },
    { name: 'Autre', sub: [] },
  ],
  Assurance: [
    { name: 'Assurance voiture', sub: [] },
    { name: 'Assurance habitation', sub: [] },
    { name: 'Assurance obsèques', sub: [] },
    { name: 'Assurance vieillesse des parents au foyer', sub: [] },
    { name: 'Assurance scolaire', sub: [] },
    { name: 'Assurance responsabilité civile', sub: [] },
    { name: 'Assurance emprunteur', sub: [] },
    { name: 'Autre', sub: [] },
  ],
  Investissement: [
    { name: 'SCPI', sub: [] },
    { name: 'LMNP', sub: [] },
    { name: 'Pinel', sub: [] },
    { name: 'Résidence principal', sub: [] },
    { name: 'Rachat de crédit', sub: [] },
    { name: 'Autre', sub: [] },
  ],
  Autre: [{ name: 'Autre', sub: [] }],
};

const frenchAreas = [
  'Toute la France',
  'Auvergne-Rhône-Alpes',
  'Bourgogne-Franche-Comté',
  'Bretagne',
  'Centre-Val de Loire',
  'Corse',
  'Grand Est',
  'Hauts-de-France',
  'Île-de-France',
  'Normandie',
  'Nouvelle-Aquitaine',
  'Occitanie',
  'Pays de la Loire',
  "Provence-Alpes-Côte d'Azur",
];

const SellerOffer = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState('');

  const [channels, setChannels] = useState([]);
  const [industry, setIndustry] = useState('Rénovation');
  const [subIndustry, setSubIndustry] = useState('Pompe à chaleur');
  const [fields, setFields] = useState([]);

  const [offer, setOffer] = useState<any>({
    type: 'leads',
    channels: [],
    industry: 'Rénovation',
    subIndustry: 'Pompe à chaleur',
    regions: ['Toute la France'],
    fields: ['prénom', 'nom', 'email', 'téléphone'],
    websiteInfo: '',
    weeklyQuantity: '',
    sellerPrice: '',
    franceOnly: true,
    //leadNbr: "",
  });

  const handlechange = (e: any) => {
    const { id, value } = e.target;

    if (id === 'weeklyQuantity' && value > 500) {
      setOffer({ ...offer, [id]: 500 });
    } else {
      setOffer({ ...offer, [id]: value });
    }
    setError('');
  };

  // const getPricing: any = () => {
  //   const indus = window.localStorage.getItem('industry');
  //   if (industriesPricing[indus]) return industriesPricing[indus].pricing;
  //   return [
  //     '15-20',
  //     '20-25',
  //     '25-30',
  //     '30-35',
  //     '35-40',
  //     '40-45',
  //     '45-50',
  //     '50-55',
  //     '55-60',
  //   ];
  // };

  const handleFieldsChange = (e: any) => {
    const { id, checked } = e.target;
    let newFields = [...fields, id];
    newFields = [...new Set([...newFields, ...['Téléphone', 'Nom', 'Prénom']])];

    if (checked) {
      setFields(newFields);
    } else {
      newFields = fields.filter(fields => fields !== id);
      setFields(newFields);
    }
    let newE = {
      target: {
        id: 'fields',
        value: newFields,
      },
    };
    handlechange(newE);
  };

  const handleChannelChange = (e: any) => {
    const { id, checked } = e.target;
    let newChannels = [...channels, id];
    if (checked) {
      setChannels(newChannels);
    } else {
      newChannels = channels.filter(channel => channel !== id);
      setChannels(newChannels);
    }
    let newE = {
      target: {
        id: 'channels',
        value: newChannels,
      },
    };
    handlechange(newE);
  };

  const handleIndustryChange = (e: any) => {
    const { value } = e.target;
    window.localStorage.setItem('industry', value);
    setIndustry(value);
    setSubIndustry(industries[value][0].name);
    setOffer({ ...offer, industry: value, subIndustry: industries[value][0].name })
  };

  const handleSubIndustryChange = (e: any) => {
    const { value } = e.target;
    setSubIndustry(value);
    handlechange(e);
  };

  useEffect(() => {
    window.localStorage.setItem('industry', industry);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(offer.industry, offer.subIndustry);
  }, [offer]);

  const steps = [
    { stepNo: 1, name: 'Création de votre demande de leads', error: error },
    { stepNo: 2, name: 'Pricing', error: error },
  ];

  const createOffer = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${CONFIG.apiUrl}/seller-offer`, offer, config)
      .then((res: any) => {
        window.location.href = '/';
      })
      .catch((err: any) => {
        setError(err.response.data.error);
      });
  };

  const handleClick = (direction: string) => {
    console.log(offer);
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;
    // check if steps are within bounds
    if (newStep > 0 && newStep <= steps.length) {
      if (offer.channels.length > 0 && offer.websiteInfo !== '')
        setCurrentStep(newStep);
      else setError('Veuillez remplir tous les champs obligatoires');
    }
    if (newStep === 3) {
      console.log('submit');
      createOffer();
    }
  };

  return (
    <div className="mt-3 h-full w-full">
      <div className="absolute z-10 ml-10 mt-2 items-center text-center font-poppins text-[26px] font-bold uppercase text-white">
        Leadrs.co
      </div>
      <div className="absolute z-10 ml-10 mt-10 items-center text-center font-poppins text-[14px] font-bold text-white">
        Ajouter une offre
      </div>

      <div className="z-0 h-[00px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[300px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]"></div>
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            {/* Stepper One */}
            <div
              className={`${
                steps[currentStep - 1].stepNo === 2 && 'hidden'
              } h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}
            >
              {/* Header */}
              <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Création de votre demande de leads
              </h4>

              {/* Error message */}
              {error && (
                <div className="z-10 ml-1 mt-2 items-center font-poppins text-[14px] text-red-500">
                  {error}.
                </div>
              )}

              {/* content */}
              {/* inputs */}
              <div className="mt-6 grid grid-cols-2 gap-3">
                <div className="col-span-2 md:col-span-1">
                  {/* Type */}
                  <div className="mb-0 mt-4">
                    <label
                      htmlFor="currency"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Région*
                    </label>
                    <div className="mt-2 w-full rounded-xl px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <Select
                        defaultValue={[
                          { value: 'france', label: 'Toute la France' },
                        ]}
                        isMulti
                        onChange={items => {
                          const regions: any = [];
                          items.map(item => regions.push(item.value));

                          let newE = {
                            target: {
                              id: 'regions',
                              value: regions,
                            },
                          };
                          handlechange(newE);
                        }}
                        name="colors"
                        options={frenchAreas.map(item => ({
                          value: item,
                          label: item,
                        }))}
                        className="w-full"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                  {/* channels */}
                  <div className="mt-3">
                    <label
                      htmlFor="currency"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Canaux d'acquisition*
                    </label>

                    <div className="ml-3 mt-4 grid grid-cols-5 gap-3">
                      {allChannels.map(channel => (
                        <div className="col-span-3 md:col-span-1">
                          <Checkbox
                            defaultChecked={false}
                            id={channel}
                            onChange={handleChannelChange}
                          />
                          <p>{channel}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Type */}
                  <div className="mb-0 mt-4">
                    <label
                      htmlFor="currency"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Industrie*
                    </label>
                    <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <select
                        id="industry"
                        className="mb-0 w-full bg-white dark:!bg-navy-800"
                        onChange={handleIndustryChange}
                      >
                        {Object.keys(industries).map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-2 md:col-span-1">
                  {/* Sous-Industrie */}
                  <div className="mb-0">
                    <label
                      htmlFor="currency"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Sous-Industrie*
                    </label>
                    <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <select
                        id="subIndustry"
                        className="mb-0 w-full bg-white dark:!bg-navy-800"
                        onChange={handleSubIndustryChange}
                      >
                        {industries[industry].map((item: any) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {(industry === 'Autre' || subIndustry === 'Autre') && (
                    <div className="mb-0 ml-2 mt-2">
                      <label
                        htmlFor={'others'}
                        className={`ml-1.5 text-sm font-bold text-navy-700 dark:text-white`}
                      >
                        Autre
                      </label>
                      <p className="mt-2 text-sm">
                        Contactez charlotte@leadrs.co pour ajouter votre
                        industrie sur Leadrs
                      </p>
                    </div>
                  )}

                  {/* SSous-sous-Industrie */}
                  {/* find element in industries[industry] who contain subIndustry */}
                  {industries[industry].find(
                    (item: any) => item.name === subIndustry
                  ).sub.length > 0 && (
                    <div className="mb-0 mt-3">
                      <label
                        htmlFor="currency"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Détails sous-industrie*
                      </label>
                      <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                        <select
                          id="subIndustry"
                          className="mb-0 w-full bg-white dark:!bg-navy-800"
                          onChange={handlechange}
                        >
                          {/* Find industries[industry] where name === subIndustry */}
                          {industries[industry].map((item: any) => {
                            if (item.name === subIndustry) {
                              return item.sub.map((subItem: any) => (
                                <option value={subItem}>{subItem}</option>
                              ));
                            }
                            return null;
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* text area */}
                  <div className="mt-5">
                    <TextField
                      label="Votre site web*"
                      placeholder="Avez vous deja un site internet actif ? Si oui quel CMS utilisez-vous ?"
                      id="websiteInfo"
                      onChange={handlechange}
                      cols={30}
                      rows={4}
                    />
                  </div>
                  <div className="mt-7">
                    <TextField
                      label="Thématique de votre site"
                      placeholder="Pouvez vous expliquer en quelques phrase la thématique de votre site internet ?"
                      id="description"
                      onChange={handlechange}
                      cols={30}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Stepper Two */}
            <div
              className={`${
                steps[currentStep - 1].stepNo === 1 && 'hidden'
              } h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}
            >
              {/* Header */}
              <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Pricing
              </h1>
              {/* Error message */}
              {error && (
                <div className="z-10 ml-1 mt-2 items-center font-poppins text-[14px] text-red-500">
                  {error}.
                </div>
              )}
              {/* inputs */}
              <div className="mt-10 grid h-full w-full grid-cols-1 gap-3 md:grid-cols-2">
                {/* left side */}
                <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-3">
                  <InputField
                    label="Prix par lead"
                    placeholder="260"
                    id="sellerPrice"
                    type="number"
                    onChange={handlechange}
                  />
                  <InputField
                    label="Quantité de leads (500 max)"
                    placeholder="260"
                    id="weeklyQuantity"
                    type="number"
                    onChange={handlechange}
                  />
                </div>
                <div className="col-span-12 mt-3">
                  <label
                    htmlFor="currency"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Critères de votre lead
                  </label>
                  <div className="ml-3 mt-4 grid grid-cols-12">
                    {industriesPricing[industry].fields
                      .concat(['Téléphone', 'Email', 'Nom', 'Prénom'])
                      .reverse()
                      .map((field: string) => (
                        <div className="col-span-4 mt-2">
                          <Checkbox
                            defaultChecked={[
                              'Téléphone',
                              'Nom',
                              'Prénom',
                            ].includes(field)}
                            id={field}
                            extra="mt-1"
                            onChange={handleFieldsChange}
                          />
                          <p>{field}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* navigation button */}
          <div className="flex justify-end text-center">
            {steps[currentStep - 1].stepNo === 2 && (
              <button
                onClick={() => setCurrentStep(1)}
                className="mt-5 text-sm"
              >
                Retour
              </button>
            )}
            <StepperControl
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SellerOffer;
