import Card from "components/card";
const MyLeads = () => {

    return (
       <>
            <Card extra={"relative w-full col-span-4 mt-0 px-2 py-6 border px-5"} key="0">
                 <p className="">Dans cette section, vous trouverez l'intégralité de vos leads disponibles, afin de consulter et gérer toutes les opportunités en un seul endroit</p>
            </Card>
        </>
    );
};

export default MyLeads;
