import Card from "components/card";

const Integrate = () => {
  return (
    <>
      <Card extra={"relative w-full col-span-4 mt-0 px-5 py-6 border bg-white shadow-md rounded-xl"} key="0">
        <div className="flex flex-col items-center text-center">
          <h2 className="text-lg font-bold mb-4">
            Intégrez facilement Leadrs à votre application
          </h2>
          <p className="text-gray-700 mb-6">
            Soyez parmi les premiers à accéder à cette fonctionnalité exclusive.
            Consultez notre documentation pour plus de détails.
          </p>
          <button
            className="bg-leadrs text-white font-semibold py-2 px-4 rounded-lg"
            onClick={() => window.open('https://speckled-move-ba3.notion.site/Documention-Leadrs-7d64d57b47ad4e56b2b33a1869db70c8', '_blank')}
          >
            Accéder à la documentation
          </button>
        </div>
      </Card>
    </>
  );
};

export default Integrate;