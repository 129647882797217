import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Card from 'components/card';
import { useEffect } from 'react';
import {
  MdDoneOutline,
  MdOutlinePayment,
  MdOutlineRocketLaunch,
} from 'react-icons/md';

const ModalSeller = (props: {
  isOpen: any;
  onOpen: any;
  onClose: any;
  selectedOffer: any;
  isKYCVerified: boolean;
  isValidated?: boolean;
}) => {
  const { isOpen, onClose, selectedOffer, isKYCVerified, isValidated } = props;
  // const [finalPrice, setFinalPrice] = useState(null);
  // const [error, setError] = useState(null);

  // const editPrice = (value: string) => {
  //   const min = Number(selectedOffer.targetPrice.split('-')[0]);
  //   const max = Number(selectedOffer.targetPrice.split('-')[1]);
  //   if (Number(value) < min * 0.9 || Number(value) > max * 0.9) {
  //     setError(
  //       'Le prix doit être compris entre ' + min * 0.9 + ' et ' + max * 0.9
  //     );
  //     return;
  //   }
  //   setError(null);
  //   setFinalPrice(value);
  // };

  // const selectOffer = () => {
  //   if (!finalPrice) {
  //     setError('Veuillez entrer un prix.');
  //     return;
  //   }
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + localStorage.getItem('token'),
  //     },
  //   };
  //   axios
  //     .post(
  //       `${CONFIG.apiUrl}/offer/${selectedOffer._id}/select`,
  //       { finalPrice: finalPrice },
  //       config
  //     )
  //     .then(res => {
  //       console.log(res.data);
  //       window.location.href = '/admin/dashboard';
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  const InfoBox = (props: {
    icon?: any;
    title?: string;
    description?: string;
  }) => {
    const { icon, title, description } = props;
    return (
      <div className="w-full rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
        <div className="grid grid-cols-12 gap-0">
          <div className="col-span-1 text-gray-900">{icon}</div>
          <h4 className="col-span-10 text-xl font-bold text-gray-900 dark:text-white">
            {title}
          </h4>
        </div>
        <p className="mt-[12px] pr-2 text-base text-gray-700 dark:text-white">
          {description}
        </p>
      </div>
    );
  };

  useEffect(() => {
    console.log(selectedOffer);
  }, [selectedOffer]);

  return (
    <>
      {selectedOffer && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
            <ModalBody>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[750px] flex flex-col !z-[1004] h-[600px] overflow-y-scroll">
                <button
                  className={`w-6 linear absolute right-[30px] rounded-full border-2 border-red-500 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10`}
                  onClick={onClose}
                >
                  X
                </button>
                <h1 className="mb-[20px] text-2xl font-bold">
                  {selectedOffer.subIndustry}
                </h1>
                <h1 className="mb-[20px] text-xl">{selectedOffer.industry}</h1>
                {selectedOffer.status === 'done' && (
                  <InfoBox
                    icon={<MdDoneOutline size={30} />}
                    title={'Mission terminée'}
                    description={`Vous avez généré ${
                      selectedOffer.leadsGenerated
                    } (${
                      selectedOffer.leadsGenerated *
                      selectedOffer.sellerPrice.toFixed(2)
                    }€) leads. Profitez de cette traction et cherchez un autre acheteur avec le même besoin`}
                  />
                )}
                {selectedOffer.status === 'running' && (
                  <InfoBox
                    icon={<MdOutlineRocketLaunch size={30} />}
                    title={'Mission en cours'}
                    description={`Vous avez déjà généré ${
                      selectedOffer.leadsGenerated *
                      selectedOffer.sellerPrice.toFixed(2)
                    }€ (${
                      selectedOffer.leadsGenerated
                    } leads) leads pour cette mission. Le payement est effectué chaque semaine sur votre compte bancaire.`}
                  />
                )}
                {selectedOffer.status === 'waiting_payment' && (
                  <InfoBox
                    icon={<MdOutlinePayment size={30} />}
                    title={'En attente de paiement'}
                    description={`Vous pourrez commencer votre campagne une fois la commande payée par
					l'acheteur. En attendant, si un lead est généré, nous nous assurons de
					vous le rediriger par mail.`}
                  />
                )}
                {!isKYCVerified && (
                  <InfoBox
                    icon={<MdOutlinePayment size={30} />}
                    title={'Coordonées bancaires manquantes'}
                    description={`Avant de vous positionner sur une offre, vous devez renseigner vos coordonées bancaires pour recevoir vos paiements.`}
                  />
                )}
                {!isValidated && (
                  <p className="text-red-400">
                    Nous sommes en train de vérifier votre compte. Vous ne
                    pouvez pas encore postuler à une offre.
                  </p>
                )}
                {selectedOffer.status === 'on_market' &&
                  isKYCVerified &&
                  isValidated && (
                    <div className="rounded-2xl bg-lightPrimary px-3 py-3 ">
                      <p className="ml-2 text-sm">
                        Cette offre est disponible sur la marketplace pour {selectedOffer.sellerPrice}€ par lead.
                      </p>
                    </div>
                  )}
                <div className="mt-3 h-px w-full bg-red-500"></div>
                <p className="mb-[20px] mt-5 flex items-center gap-5 font-bold">
                  Récapitulatif:
                </p>
                <div className="mb-[20px] mt-2 flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Quantité:</p>
                  <p className="mb-[20px]">
                    {selectedOffer.weeklyQuantity} leads
                  </p>
                </div>
                <div className="mb-[20px] flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Champs de données:</p>
                  <p className="mb-[20px]">{selectedOffer.fields.join(', ')}</p>
                </div>
                <div className="mb-[20px] flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Canaux d'acquisition:</p>
                  <p className="mb-[20px]">
                    {selectedOffer.channels.join(', ')}
                  </p>
                </div>
                <div className="mb-[20px] flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Besoin:</p>
                  <p className="mb-[20px]">{selectedOffer.type}</p>
                </div>
                <div className="mb-[20px] flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Site web:</p>
                  <p className="mb-[20px]">{selectedOffer.websiteInfo}</p>
                </div>
                <div className="mb-[20px] flex items-center gap-5">
                  <p className="mb-[20px] font-bold">Description:</p>
                  <p className="mb-[20px]">{selectedOffer.description}</p>
                </div>
                <div className="mb-3 flex items-center gap-5">
                  <p className="mb-2 font-bold"> Regions: </p>
                  <p className="mb-2">{selectedOffer.regions.join(', ')}</p>
                </div>
                <div className="mt-5 flex gap-2">
                  <button
                    onClick={onClose}
                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                  >
                    Quitter
                  </button>
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
export default ModalSeller;
