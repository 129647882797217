import {
  MdAnalytics,
  MdHome,
  MdKey,
  MdLock,
  MdLockOutline,
  MdOutlineLocalOffer,
} from 'react-icons/md';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCenter';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import LockCentered from 'views/auth/lock/LockCenter';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCenter';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import SignUpSeller from 'views/auth/signUp/SignUpSeller';
//import DashboardsDefault from 'views/admin/dashboards/default';
import SellerDashboard from 'views/admin/nfts/collection';
import ProfileSettings from 'views/admin/main/profile/settings';

// Admin Imports

// Main Imports

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';
import StripeBilling from 'views/admin/main/account/stripe';
import SellerOffer from 'views/admin/main/ecommerce/sellerOffer';
import BuyerOffer from 'views/admin/main/seller/buyerOffer';
import MyOffer from 'views/admin/main/seller/myOffer';
import { FaBorderAll, FaEye } from 'react-icons/fa';
import Integrate from 'views/admin/main/seller/integrate';
import SellerReporting from 'views/admin/main/seller/sellerReporting';

const routes = [
  {
    name: 'Marketplace',
    layout: '/admin',
    path: '/dashboard',
    icon: <FaBorderAll className="text-inherit h-6 w-6" />,
    component: <BuyerOffer />,
    menu: true,
  },
  // {
  //   name: 'Tableaux de bord',
  //   layout: '/admin',
  //   path: '/my-request',
  //   icon: <MdHome className="text-inherit h-6 w-6" />,
  //   component: <DashboardsDefault />,
  //   menu: false,
  // },
  {
    name: 'Profile Settings',
    layout: '/admin',
    path: '/settings',
    exact: false,
    icon: <MdHome className="text-inherit h-6 w-6" />,
    component: <ProfileSettings />,
  },
   {
    name: 'Mes offres',
    layout: '/admin',
    path: '/my-offer',
    icon: <MdOutlineLocalOffer className="text-inherit h-6 w-6" />,
    component: <MyOffer />,
    menu: true,
  },
  {
    name: 'Benchmark',
    layout: '/admin',
    path: '/benchmark',
    icon: <FaEye className="text-inherit h-6 w-6" />,
    component: <SellerDashboard />,
    menu: true,
  },
  {
    name: 'Intégration',
    layout: '/admin',
    path: '/connect',
    icon: <MdKey className="text-inherit h-6 w-6" />,
    menu: true,
    component: <Integrate />
  },
  {
    name: 'Reporting',
    layout: '/admin',
    path: '/reporting',
    icon: <MdAnalytics className="text-inherit h-6 w-6" />,
    menu: true,
    component: <SellerReporting />,
  },
  {
    name: 'Paiement',
    layout: '/admin',
    path: '/paiement',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    availble: false,
    menu: true,
  },
  {
    name: 'Attente de validation',
    layout: '/admin',
    path: '/seller/integrate',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceReferrals />,
  },
  {
    name: 'Créer une offre',
    layout: '/admin',
    path: '/new-offer',
    exact: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceNewProduct />,
  },
  {
    name: 'Créer une offre',
    layout: '/admin',
    path: '/seller-offer',
    exact: false,
    menu: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <SellerOffer />,
  },
  {
    name: 'Abonnements',
    layout: '/admin',
    path: '/subscribe',
    exact: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <StripeBilling />,
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up',
            component: <SignUpDefault />,
          },
        ],
      },
      {
        name: 'Sign Up',
        path: '/seller/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/seller/sign-up',
            component: <SignUpSeller />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
];
export default routes;
