import {
    MdEuro,
    MdOutlinePersonPin,
    MdOutlineShoppingBasket,
    MdPallet,
} from 'react-icons/md';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GiCargoCrate } from 'react-icons/gi';
import { TbMoneybag, TbPigMoney } from 'react-icons/tb';

import MiniStatistics from 'components/card/MiniStatistics';
import LosingEstimation from './components/ProfitEstimation';
import StockValue from './components/ProjectStatus';
import Card from 'components/card';
import AverageBasket from './components/averageBasket';
import OrdersLines from './components/OrdersLines';
import BenefitLines from './components/BenefitLines';
import TopCustomersMap from './components/TopCustomersMap';
import VolumeTable from './components/volumeTable';

// function getToday() {
//     return new Date().toISOString().split('T')[0];
// }

// function getSevenDaysAgo() {
//     const d = new Date();
//     d.setDate(d.getDate() - 7);
//     return d.toISOString().split('T')[0];
// }

const ReportingDashboard = () => {
    const navigate = useNavigate();
    const [data] = useState<any>(null);
    const [allDates] = useState<any>([]);

    return (
        <>
            <Card extra={"mb-2 relative w-full col-span-4 mt-0 px-2 py-6 border px-5"} key="0">
                    <p className="font-semibold">La fonctionnalité de reporting et d'analytics arrive bientôt pour vous offrir des insights détaillés sur vos leads et vos performances.</p>
            </Card>
            <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
                <div className="h-full w-full rounded-[20px]">
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Statistics */}
                        <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                            <div id="step-one">
                                <MiniStatistics
                                    name="Leads générés"
                                    value={data ? `${data.todayBenefit} €` : '-'}
                                    icon={
                                        <MdOutlineShoppingBasket color="#0260CB" />
                                    }
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Budget dépensé"
                                    value={
                                        data ? `${data.benefitPerPeriod} €` : '-'
                                    }
                                    icon={<MdEuro color="#0260CB" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Nombre de commandes"
                                    value={
                                        data ? `${data.volumePerPeriod} Kg` : '-'
                                    }
                                    icon={<TbMoneybag color="#0260CB" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Coût par commande"
                                    value={
                                        data ? `${data.totalOrdersPerPeriod}` : '-'
                                    }
                                    icon={<TbPigMoney color="#0260CB" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                        </div>
                        {/* Filter */}
                        {/* Main data */}
                        <div className="mt-5 grid w-full grid-cols-8 gap-5">
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                                    <BenefitLines
                                        data={data?.sparkLinesBenefit}
                                        allDates={allDates}
                                    />
                                    <OrdersLines
                                        data={data?.sparklinesOrders}
                                        allDates={allDates}
                                    />
                                    <AverageBasket
                                        data={data?.sparklinesBasket}
                                        allDates={allDates}
                                    />
                                    <TopCustomersMap data={data?.topCustomers} />
                                    <div className="col-span-1 md:col-span-2">
                                        <VolumeTable data={data?.volumeTable} />
                                    </div>
                                    <StockValue data={data} />
                                    <LosingEstimation data={data} />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                                    <Card
                                        extra={
                                            'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                        }
                                    >
                                        <MdPallet className="h-10 w-10" />
                                        <button
                                            className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                            onClick={() =>
                                                navigate('/create-order')
                                            }
                                        >
                                            Créer une commande
                                        </button>
                                    </Card>
                                    <Card
                                        extra={
                                            'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                        }
                                    >
                                        <MdOutlinePersonPin className="h-10 w-10" />
                                        <button
                                            className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                            onClick={() =>
                                                navigate('/create-customer')
                                            }
                                        >
                                            Créer un client
                                        </button>
                                    </Card>
                                    <Card
                                        extra={
                                            'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                        }
                                    >
                                        <GiCargoCrate className="h-10 w-10" />
                                        <button
                                            className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                            onClick={() =>
                                                navigate(
                                                    '/delivery/delivery-list?f=1',
                                                )
                                            }
                                        >
                                            Mes livraisons
                                        </button>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportingDashboard;
