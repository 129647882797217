import InputField from 'components/fields/InputField';
import Checkbox from 'components/checkbox';
import { useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';

function SignUpSeller() {
  const [error, setError] = useState('');
  const [newUser, setNewUser] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    siret: '',
    type: 'seller',
    role: 'ceo',
    accept_tos: false,
    // Linked to service
    business: 'freelance',
    industry: 'Rénovation',
    channels: ['google'],
    confirmPassword: '',
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    if (id === 'channels') {
      setNewUser(prevState => ({
        ...prevState,
        [id]: [value],
      }));
      return;
    }

    if (id === 'accept_tos') {
      setNewUser(prevState => ({
        ...prevState,
        [id]: !prevState.accept_tos,
      }));
      return;
    }
    setNewUser(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setError('');
    console.log(newUser);
  };

  const handleSubmit = (e: any) => {
    console.log(newUser);
    console.log(newUser.password, newUser.confirmPassword);
    if (newUser.password !== newUser.confirmPassword) return;
    delete newUser.confirmPassword;
    axios
      .post(`${CONFIG.apiUrl}/auth/register`, newUser)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem('token', res.data.token);
         localStorage.setItem('user-type', newUser.type);
        if (res.data && res.data.token.length > 10)
          window.location.href = '/admin/seller/integrate';
      })
      .catch((err: any) => {
        console.log(err.response.data.error);
        setError(err.response.data.error);
      });
  };

  return (
    <div className="relative flex">
      <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0">
        <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
          <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
            {/* Sign up section */}
            <div className="mt-10 w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[520px]">
              <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                Rejoindre Leadrs !
              </h3>
              <p className="ml-1 mt-[10px] text-base text-gray-600">
                Vendre facilement vos leads.
              </p>
              <p className="ml-1 mt-[10px] text-base text-gray-600">
                <span className="text-red-500"> {error} </span>
                {newUser.password !== newUser.confirmPassword && (
                  <span className="text-red-500">
                    {' '}
                    Les mots de passes ne correspondent pas
                  </span>
                )}
              </p>
              {/* user info */}
              <p className="text-bold ml-1 mt-5 text-gray-900">
                1 - Informations de votre activité.
              </p>
              <div className="mb-3 mt-3 grid w-full grid-cols-1 items-center justify-center gap-4 md:grid-cols-3">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Prénom*"
                  placeholder="Jeff"
                  id="name"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Nom*"
                  placeholder="Bezos"
                  id="surname"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Email*"
                  placeholder="Jeff@amazon.fr"
                  id="email"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Téléphone*"
                  placeholder="+33 ..."
                  id="phone"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="URL landing page*"
                  placeholder="amazon.fr"
                  id="company"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Siret*"
                  placeholder="5748 8587 54885"
                  id="siret"
                  type="text"
                  onChange={handleChange}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Mot de passe*"
                  placeholder="******"
                  id="password"
                  type="password"
                  onChange={handleChange}
                />
                <div className="col-span-1 md:col-span-2">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Confirmer mot de passe*"
                    placeholder="*******"
                    id="confirmPassword"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <p className="text-bold ml-1 mt-5 text-gray-900">
                2 - Informations sur vos services
              </p>
              <div className="mb-3 mt-3 grid w-full grid-cols-1 items-center justify-center gap-4 md:grid-cols-3">
                <div className="mb-3">
                  <label
                    htmlFor="business"
                    className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                  >
                    Je suis*
                  </label>
                  <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <select
                      id="business"
                      className="mb-0 w-full bg-white dark:!bg-navy-800"
                      onChange={handleChange}
                    >
                      {[
                        { value: 'agency', label: 'Une agence' },
                        { value: 'freelance', label: 'Un freelance' },
                      ].map(item => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="industry"
                    className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                  >
                    Secteur*
                  </label>
                  <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <select
                      id="industry"
                      className="mb-0 w-full bg-white dark:!bg-navy-800"
                      onChange={handleChange}
                    >
                      {[
                        'Rénovation',
                        'Immobilier',
                        'Assurance',
                        'Formation',
                      ].map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="channels"
                    className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                  >
                    Spécialité*
                  </label>
                  <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <select
                      id="channels"
                      className="mb-0 w-full bg-white dark:!bg-navy-800"
                      onChange={handleChange}
                    >
                      {[
                        'Google Ads',
                        'Bing Ads',
                        'Taboola',
                        'Facebook',
                        'Instagram',
                        'Twitter',
                        'LinkedIn',
                        'Pinterest',
                        'SEO',
                        'Emailing',
                        'SMS Marketing',
                        'Newsletter',
                        'Publicités display',
                        'Tik Tok',
                      ].map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-3">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Combien de leads par semaine je pense pouvoir générer ?*"
                    placeholder="700"
                    id="leadsPerWeek"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Checkbox */}
              <div className="mt-4 flex items-center justify-between px-2">
                <div className="flex">
                  <Checkbox id="accept_tos" onClick={handleChange} />
                  <label
                    htmlFor="checkbox"
                    className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                  >
                    Je suis le représentant légal de l'entreprise et accepte les{' '}
                    <a
                      href="/auth/sign-in/default"
                      className="ml-1 text-sm font-medium text-leadrs hover:text-brand-600 dark:text-white"
                    >
                      Conditions Générales d'Utilisation
                    </a>
                  </label>
                </div>
              </div>

              {/* button */}

              <button
                className="linear mt-4 w-full rounded-xl bg-[#7166fe] py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-[#7166fe] dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={e => handleSubmit(e)}
              >
                M'inscrire
              </button>

              <div className="mt-3">
                <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                  Déjà inscrit ?
                </span>
                <a
                  href="/auth/sign-in/"
                  className="ml-1 text-sm font-medium text-leadrs hover:text-brand-600 dark:text-white"
                >
                  Se connecter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpSeller;
