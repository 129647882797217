import { useState } from "react";
import Card from "components/card";
import SalesForceImg from "assets/salesforce.png";
import HubspotImg from "assets/hubspot.png";
import PipedriveImg from "assets/pipedrive.png";
import ActiveCampaingImg from "assets/activecampaign.png";
import MondayImg from "assets/monday.png";
import GsheetImg from "assets/gsheet.png";
import { useNavigate } from "react-router-dom";

const Integrate = () => {
    const [loading] = useState(false);
    const [services] = useState([
        {
            name: "SalesForce",
            service: "meta",
            description:
                "Recevez vos leads directement dans votre compte SalesForce.",
            logo: SalesForceImg,
            status: "coming-soon",
            onLogin: () => {},
        },
        {
            name: "Hubspot",
            service: "gmb",
            description:
                "Recevez vos leads directement dans votre compte Hubspot.",
            logo: HubspotImg,
            status: "coming-soon",
            onLogin: () => {},
        },
        {
            name: "PipeDrive",
            service: "fork",
            description:
                "Recevez vos leads directement dans votre compte PipeDrive.",
            logo: PipedriveImg,
            status: "coming-soon",
            onLogin: () => {},
        },
        {
            name: "Monday",
            service: "fork",
            description:
                "Recevez vos leads directement dans votre compte Monday.",
            logo: MondayImg,
            status: "coming-soon",
            onLogin: () => {},
        },
        {
            name: "Active Campaign",
            service: "fork",
            description:
                "Recevez vos leads directement dans votre compte Active Campaign.",
            logo: ActiveCampaingImg,
            status: "coming-soon",
            onLogin: () => {},
        },
        {
            name: "Google Sheet",
            service: "gsheet",
            description:
                "Recevez vos leads directement depuis votre compte Google Sheet.",
            logo: GsheetImg,
            status: "coming-soon",
            onLogin: () => {},
        },
    ]);

    const PluginCard = (props: {
        logo: any;
        name: string;
        service: string;
        description: string;
        status?: string;
        index: number;
        onLogin?: () => void;
    }) => {
        const { logo, name, description, service, status, onLogin, index } = props;
        const nav = useNavigate();

        return (
            <Card extra={"relative w-full mt-4 px-2 py-6 border"} index={index + 1} key={index + 1}>
                {/* Badge */}
                {status === "online" && (
                    <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Connecté"}
                    </div>
                )}
                {status === "offline" && (
                    <div className="absolute top-3 right-3 bg-red-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Déconnecté"}
                    </div>
                )}
                {status === "not-setup" && (
                    <div className="absolute top-3 right-3 bg-orange-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Non configuré"}
                    </div>
                )}
                {/* Header */}
                <div className="w-full px-1">
                    <img
                        src={logo}
                        alt={`${name}-logo`}
                        className="ml-1 w-[70px] h-[70px] border rounded-lg"
                    />
                    <h4 className="mt-4 text-xl font-medium">{name}</h4>
                    <p className="mt-2 text-xs">{description}</p>
                </div>
                <div className="mt-4 flex justify-center">
                    {status === "coming-soon" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-leadrs py-3 font-medium text-white w-full cursor-not-allowed`}
                        >
                            Bientôt disponible
                        </button>
                    )}
                    {status === "not-setup" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-leadrs py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={onLogin}
                        >
                            Associer un compte
                        </button>
                    )}
                    {status === "online" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-primary py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={() => nav(`/integrate/${service}`)}
                        >
                            Gérer les paramètres
                        </button>
                    )}
                    {status === "offline" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-primary py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={onLogin}
                        >
                            Me reconnecter
                        </button>
                    )}
                </div>
            </Card>
        );
    };

    return (
        <div>
            {loading ? (
                <div className="p-10 border-2 border-[#1f2937] rounded-xl">
                    <p className="text-2xl font-han text-center mb-[80px]">
                        Chargement des extensions...
                    </p>
                </div>
            ) : (
              <>
              <Card extra={"relative w-full col-span-4 mt-0 px-2 py-6 border px-5"} key="0">
                    <p className="font-bold">Nous avons simplifié la manière dont vous recevez vos leads !</p>
                    <p className="mt-2 text-sm">Désormais, au lieu de gérer vos prospects uniquement par email, vous pouvez les recevoir directement dans votre CRM préféré. En connectant votre CRM à notre plateforme, vos leads seront automatiquement transférés, prêts à être traités sans perte de temps.</p>
                    <p className="mt-2 text-sm">Cette intégration fluide permet de centraliser toutes vos données, d'améliorer votre suivi, et d'optimiser votre gestion des prospects. Dites adieu aux emails encombrés et profitez d’un flux de travail plus efficace et structuré avec vos leads directement là où vous en avez besoin !</p>
                    </Card>
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {services.map((service, index) => (
                        <PluginCard
                          index={index}
                            key={service.service}
                            name={service.name}
                            service={service.service}
                            description={service.description}
                            logo={service.logo}
                            status={service.status}
                        />
                    ))}
                </div>
                </>
            )}
        </div>
    );
};

export default Integrate;
