import Card from "components/card";
import "react-circular-progressbar/dist/styles.css";
import { MdLightbulbOutline, MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { useEffect, useState } from "react";

function LosingEstimation(props: { data: any }) {
    const { data } = props;
	const [alert, setAlert] = useState(null);
	const [index, setIndex] = useState(0);

	const handleNext = () => {
        if (index === data.lossRates.length - 1) return;
        setIndex(index + 1);
        setAlert(data.lossRates[index + 1]);
    };

    const handlePrev = () => {
        if (index === 0) return;
        setIndex(index - 1);
        setAlert(data.lossRates[index - 1]);
    };

	useEffect(() => {
        if (data && data.lossRates.length > 0)
            setAlert(data.lossRates[0]);
        else
            setAlert({
                name: "Bouteille",
                ref: "EXEMPLE-001",
                percentage: 5
            });
    }, [data]);

    return (
        <Card extra={"p-5 lg:h-full border"}>
            <div className="flex flex-col px-2 text-center">
                <p className="text-lg font-bold text-navy-700 dark:text-white">
                    Taux de perte
                </p>
                <p className="mt-2 px-4 text-sm font-medium text-gray-600">
                    Pourcentage de sorties déclarés en tant que perte
                </p>
            </div>
            <div className="flex mt-7 w-full items-start justify-between rounded-3xl bg-gray-100 px-3 py-[20px] hover:shadow-2xl">
                <div className="flex items-center gap-3">
                    <div className="flex w-[30%] items-center justify-center">
                    </div>
                    <div className="flex flex-col">
                        <h5 className="text-base font-bold text-navy-700 dark:text-white lg:text-md 3xl:text-3xl">
                            {" "}
                            {alert && alert.name}
                        </h5>
                        <p className="mt-1 text-lg font-normal text-gray-600">
                            {alert && alert.ref}
                        </p>
                    </div>
                </div>
            </div>
			{data && data.lossRates.length > 1 && (
			<div className="my-auto mx-auto mb-2 grid grid-cols-3 gap-5">
                <div className="hover:cursor-pointer"
				onClick={() => handlePrev()}>
                    <MdNavigateBefore size={30} />
                </div>
                <p className="text-lg font-normal">{`${index+1}/${data && data.lossRates ? data.lossRates.length : 0}`}</p>
                <div className="hover:cursor-pointer"
				onClick={() => handleNext()}>
                    <MdNavigateNext size={30} />
                </div>
            </div>
			)}
			{data && data.lossRates.length === 0 && (
                <div className="mt-5 mx-auto mb-3 grid grid-cols-12">
					<MdLightbulbOutline size={30} />
					<p className="ml-3 col-span-11 text-sm text-gray-600">Une fois vos premiers composants en stock, les DLC les plus proches apparaîtrons ici.</p>
				</div>
            )}
        </Card>
    );
}

export default LosingEstimation;
