import Card from "components/card";
const IA = () => {

    return (
       <>
            <Card extra={"relative w-full col-span-4 mt-0 px-2 py-6 border px-5"} key="0">
                    <p className="font-bold">Vous souhaitez avoir une surcouche de validation de vos leads ?</p>
                    <p className="mt-2 text-sm">L’Intelligence Artificielle peut le faire.</p>
                     <p className="mt-2 font-bold">Comment ca marche ?</p>
                    <p className="mt-2 text-sm">Leadrs va au-delà de la simple génération de leads. Dès qu'un lead est capturé, notre intelligence artificielle entre en action pour le requalifier via des canaux puissants et ciblés. Nous utilisons deux méthodes principales :</p>
                    <p className="mt-2 text-sm">1. Des robots d'appel intelligents qui contactent automatiquement les prospects pour valider et enrichir les informations recueillies.</p>
                    <p className="mt-2 text-sm">2. La messagerie automatique via WhatsApp pour établir un dialogue instantané, recueillir des données supplémentaires et identifier les besoins spécifiques de chaque prospect.</p>
                    <p className="mt-3 text-sm">Ce processus IA assure une requalification de haute précision, vous livrant des leads non seulement chauds, mais aussi parfaitement alignés avec vos offres.</p>
            </Card>
        </>
    );
};

export default IA;
